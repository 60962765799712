<template>

  <div class="pb">
    <h1>Experimental</h1>

    <br>

    <h2>Ui </h2>

    <div>
      <UiToggle 
        @update:modelValue="switchExperimentsDiaryWide"
        :label="'Show diaries v2'"
        v-model="experimentsDiaryWide" 
        />
    </div>

    <br>

    <h2>Adv</h2>

    <div>
      <label><input type="radio" v-model="useExperimentsRegion().value" :checked="useExperimentsRegion().value=='auto'" value="auto"> Auto</label>
    </div>
    <div>
      <label><input type="radio" v-model="useExperimentsRegion().value" :checked="useExperimentsRegion().value=='es'" value="es"> Spain</label>
    </div>
    <div>
      <label><input type="radio" v-model="useExperimentsRegion().value" :checked="useExperimentsRegion().value=='us'" value="us"> USA</label>
    </div>
    <div>
      <label><input type="radio" v-model="useExperimentsRegion().value" :checked="useExperimentsRegion().value=='th'" value="th"> Thai</label>
    </div>


  </div>

</template>

<script setup>

const { $api, $head } = useNuxtApp();
 
const experimentsDiaryWide = ref(useExperimentsDiaryWide().value ? 1 : 0)

const switchExperimentsDiaryWide = (val) => {
  console.log('val:')
  console.log(val)
  experimentsDiaryWide.value = val
  useExperimentsDiaryWide().value = val ? true : false
}



</script>

<style scoped>

.text-style-info{
    text-indent: 2rem;
}

.html_into :v-deep(.de_im){
  height: 14px;
  vertical-align: middle;
}
 
 
</style>